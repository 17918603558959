body.home #content {
  margin-top: 440px;
}

@media (max-width: 767px) {
  body.home #content {
    margin-top: 220px;
  }
}

#content {
  background-color: $white;
  margin-top: 220px;
  min-height: 400px;
  padding-bottom: 4em;

  .article-body {
    margin-bottom: 40px;
  }
}

%content-links {
  a {
    &:link,
    &:visited {
      color: $spd_red;
    }

    &:hover,
    &:focus,
    &:active {
      color: $darker_red;
    }
  }
}

// #content {
//   a:link, a:visited, a:active, a:hover {
//     color: $dark_grey;
//   }
//
//   a:focus {
//     color: $dark_grey;
//   }
// }
