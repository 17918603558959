.blog-author {
  color: #999;

  a:link,
  a:visited {
    color: #777;
  }

  a:hover {
    color: $spd_red;
  }
}

.blog-tag {
  margin-right: 5px;

  a {
    background-color: #f8f8f8;
    padding: 2px 4px;
  }
}

.blog-tag:not(:last-child)::after {
  content: ',';
  margin-left: 2px;
}

.single .article-body,
.home .article-body,
.page .article-body
{
  font-size: 1.15em;
  line-height: 150%;

  img.featured.pull-left {
    margin-right: 2%;
    margin-top: 5px;
    float: left;
    clear: left;
  }
 
  img.size-full {
    height: auto !important;
    margin: 5px 0;
    max-width: 100%;
  }

  img {
    height: auto !important;
    max-width: 100%;
  }

  .alignleft {
    float: left;
    clear: left;
    margin-right: 2%;
    display: inline-block;
  }

  .alignright {
    float: right;
    clear: right;
    margin-left: 2%;
    display: inline-block;
  }

  div.aligncenter,
  img.aligncenter {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  div.wp-caption {
     max-width: 48%;
     display: inline-block;

     > * {
       margin-left: 0;
       margin-right: 0;
     }
  }

  .wp-caption-text {
    font-size: 85%;
  }

  @media (min-width: 769px) {
    *:not(.alignleft) > img.size-full.alignleft,
    *:not(.alignright) > img.size-full.alignright {
       max-width: 48%;
    }
  }

  img.size-full.alignleft {
    float: left;
    clear: left;
    margin-right: 2%;
    margin-left: 0;
  }

  img.size-full.alignright {
    float: right;
    clear: right;
    margin-right: 0;
    margin-left: 2%;
  }

  img.alignnone.size-full {
    max-width: 100%;
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    img.size-full.alignleft,
    img.size-full.alignright ,
    img.size-full.alignnone,
    div.wp-caption {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .wp-caption {
      > * {
        max-width: 100%;
      }
      width: auto;
    }
    float: none;
    clear: none;
  }
}

.article-content {
  @extend %content-links;
}
